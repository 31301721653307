<template>
  <div class="addPromotionbox">
    <div class="titleboxtwo" v-if="handData.id==''">选择促销酒店</div>
    <div class="mainbox" v-if="handData.id==''">
      <div>
        <div class="checkOne">
          <span>城市</span>
          <el-input
            size="mini"
            v-model="searchData.city"
            style="width: 110px; margin-left: 10px; background-color: #f6f8fb"
            placeholder="城市名"
          ></el-input>
        </div>
        <div class="checkOne">
          <el-select size="mini" style="width: 100px" v-model="hotelNameorId">
            <el-option label="酒店名称" value="酒店名称"></el-option>
            <el-option label="酒店ID" value="酒店ID"></el-option>
          </el-select>

          <el-input
            v-show="hotelNameorId == '酒店名称'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.hotelName"
            placeholder="请输入酒店名称"
          >
          </el-input>
          <el-input
            v-show="hotelNameorId == '酒店ID'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.hotelName"
            placeholder="请输入酒店ID"
          >
          </el-input>
        </div>
        <div class="checkOne">
          <el-select size="mini" style="width: 100px" v-model="roomNameorId">
            <el-option label="房型名称" value="房型名称"></el-option>
            <el-option label="房型ID" value="房型ID"></el-option>
          </el-select>
          <el-input
            v-show="roomNameorId == '房型名称'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.roomName"
            placeholder="请输入房型名称"
          >
          </el-input>
          <el-input
            v-show="roomNameorId == '房型ID'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.roomName"
            placeholder="请输入房型ID"
          >
          </el-input>
        </div>
        <el-button type="primary" @click="searchHotelTableFn" size="mini"
          >搜索</el-button
        >
        <div class="roomBoxOneOff" @click="setTableSwitch">
          {{ tableSwitch }}
        </div>
      </div>
      <div v-if="tableSwitch == '列表收起'" class="roomBoxOneHotelNameBox">
        <div
          class="roomBoxOneHotelName"
          :style="{ color: checkedColor == item.hotelId ? '#256DEF' : '' }"
          @click="searchHotelInfo(item.hotelId, index, item.hotelName)"
          v-for="(item, index) in hotelList"
          :key="item.hotelId"
        >
          <span style="margin-left: 10px">
            {{ item.hotelName | formatHotelName() }}
          </span>
        </div>
        <el-pagination
          v-if="total > 15"
          class="roomPagin"
          background
          small
          @current-change="handleCurrentChange"
          :page-size="15"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div class="titleboxtwo" style="color: #256def">{{ pmHotelName }}</div>
    <div class="mainbox">
      <div>
        <el-checkbox
          :indeterminate="roomIndeterminate"
          v-model="ischeckAllRoom"
          @change="handleCheckAllRoomChange"
        >
          全选
        </el-checkbox>
      </div>
      <div v-for="(item, index) in pmRoomList" :key="index">
        <div class="marginbottom20 borderbottom">
          <div class="inlineblockbox" style="width: 200px">
            <el-checkbox
              style="margin-right: 30px; margin-left: 20px"
              :indeterminate="item.indeterminate"
              v-model="item.checked"
              @change="handleCheckAllRoomItemChange(item, $event)"
            >
              {{ item.roomTypeName }}
            </el-checkbox>
          </div>
          <div class="inlineblockbox" style="width: 900px">
            <div
              class="inlineblockbox marginright20"
              v-for="ctem in item.list"
              :key="ctem.roomId"
            >
              <el-checkbox
                @change="handleCheckRoomItemChange(ctem, $event)"
                v-model="ctem.checked"
                >{{ ctem.roomName }}</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="titleboxtwo">设置促销内容</div>
    <div class="mainbox">
      <div class="newboxone">促销时间</div>
      <div class="margintop20">
        <span class="newboxlabel">预定时间</span>
        <el-date-picker
          size="mini"
          v-model="chooseDateOne"
          type="daterange"
          style="margin-left: 40px"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="margintop20 marginleft90">
        <div class="inlineblockbox margintop3">适用星期</div>
        <div class="inlineblockbox">
          <el-checkbox
            style="margin-right: 30px; margin-left: 20px"
            :indeterminate="weekIndeterminate"
            v-model="ischeckAllWeek"
            @change="handleCheckAllWeekChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            v-model="checkedWeeks"
            style="display: inline-block"
            @change="handleCheckedWeeksChange"
          >
            <el-checkbox
              v-for="item in weeks"
              :label="item.id"
              :key="item.id"
              >{{ item.week }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>

      <div class="margintop20">
        <span class="newboxlabel">入住时间</span>
        <el-date-picker
          size="mini"
          v-model="chooseDateTwo"
          type="daterange"
          style="margin-left: 40px"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="margintop20 marginleft90">
        <div class="inlineblockbox margintop3">适用星期</div>
        <div class="inlineblockbox">
          <el-checkbox
            style="margin-right: 30px; margin-left: 20px"
            :indeterminate="weekIndeterminateTwo"
            v-model="ischeckAllWeekTwo"
            @change="handleCheckAllWeekChangeTwo"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            v-model="checkedWeeksTwo"
            style="display: inline-block"
            @change="handleCheckedWeeksChangeTwo"
          >
            <el-checkbox
              v-for="item in weeks"
              :label="item.id"
              :key="item.id"
              >{{ item.week }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="margintop20 marginleft90">
        <div class="inlineblockbox margintop5 marginright10">
          <el-checkbox v-model="notApplyDate">不适用日期</el-checkbox>
        </div>
        <div class="inlineblockbox" v-if="notApplyDate">
          <div
            v-for="(item, index) in notApplyDateList"
            :key="index"
            class="marginbottom10"
          >
            <el-date-picker
              size="mini"
              v-model="item.chooseDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div
              @click="addNotApplyDate(item, index)"
              class="inlineblockbox iconbox marginleft10 margintop3"
            >
              <i class="el-icon-plus"></i>
            </div>
            <div
              @click="delNotApplyDate(item, index)"
              v-if="notApplyDateList.length > 1"
              class="inlineblockbox iconbox marginleft10 margintop3"
            >
              <i class="el-icon-minus"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="margintop40">
        <span class="newboxlabel lineheight28">促销条件</span>
        <div
          class="marginleft40 inlineblockbox"
          v-if="
            handData.promotionName == '多天连住' ||
            handData.promotionName == '提前预定'
          "
        >
          <span
            class="marginright20"
            v-if="handData.promotionName == '提前预定'"
            >早定天数</span
          >
          <span
            class="marginright20"
            v-if="handData.promotionName == '多天连住'"
            >连住天数</span
          >
          <el-radio v-model="daysValue" label="2">2天</el-radio>
          <el-radio v-model="daysValue" label="3">3天</el-radio>
          <el-radio v-model="daysValue" label="4">4天</el-radio>
          <el-radio v-model="daysValue" label="5">5天</el-radio>
          <el-radio v-model="daysValue" label="-1">自定义天数</el-radio>
          <el-input
            style="width: 100px; margin-right: 10px"
            v-model="daysValue1"
            size="mini"
            placeholder="输入折扣"
          ></el-input
          ><span>折</span>
        </div>
        <div class="marginleft40 inlineblockbox">
          <span class="marginright20">设置优惠</span>
          <el-radio v-model="discountCount" label="60">6折</el-radio>
          <el-radio v-model="discountCount" label="50">5折</el-radio>
          <el-radio v-model="discountCount" label="40">4折</el-radio>
          <el-radio v-model="discountCount" label="30">3折</el-radio>
          <el-radio v-model="discountCount" label="110">自定义折扣</el-radio>

          <el-input
            style="width: 100px; margin-right: 10px"
            v-model="discountCount1"
            size="mini"
            placeholder="输入折扣"
          ></el-input
          ><span>折</span>
        </div>
      </div>
      <div class="margintop20 marginleft90">
        <el-radio v-model="discountCount" label="120">立减</el-radio>

        <el-input
          style="width: 100px; margin-right: 10px"
          v-model="discountCount2"
          size="mini"
          placeholder="输入金额"
        ></el-input
        ><span>元</span>
      </div>
      <div
        class="margintop20 marginleft90"
        v-if="handData.promotionName == '今夜特惠'"
      >
        <span>售卖时间段</span>
        <el-select
          size="mini"
          style="width: 180px; margin-left: 10px; margin-right: 10px"
          v-model="supportStartHourValue"
        >
          <el-option
            v-for="item in timeList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
        <span>至</span>
        <span>06:00</span>
      </div>
      <div
        class="margintop20 marginleft90"
        v-if="handData.promotionName == '限时预订'"
      >
        <span>售卖时间段</span>
        <el-select
          size="mini"
          style="width: 180px; margin-left: 10px; margin-right: 10px"
          v-model="supportStartHourValue"
        >
          <el-option
            v-for="item in timeListTwo"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
        <span>至</span>
        <el-select
          size="mini"
          style="width: 180px; margin-left: 10px; margin-right: 10px"
          v-model="supportEndHourValue"
        >
          <el-option
            v-for="item in timeListThree"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div
        class="margintop20 marginleft90"
        v-if="handData.promotionName == '尾房'"
      >
        <span>售卖时间段</span>
        <el-select
          size="mini"
          style="width: 180px; margin-left: 10px; margin-right: 10px"
          v-model="supportStartHourValue"
        >
          <el-option
            v-for="item in timeListFour"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
        <span>至</span>
        <span>次日06:00</span>
      </div>
    </div>
    <div>
      <div class="titleboxtwo inlineblockbox marginright10">关联修改</div>
      <div class="inlineblockbox margintop30">
        <div
          class="inlineblockbox marginright10"
          v-for="item in pmVestList"
          :key="item.hotelVestId"
        >
          <el-checkbox
            @change="handleCheckVestChange(item, $event)"
            v-model="item.checked"
            >{{ item.horseName }}</el-checkbox
          >
        </div>
      </div>
    </div>

    <div class="titleboxtwo">
      <el-button type="primary" @click="addPromotionNotify">{{
        submitName
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { hotelList_api } from "../../apis/room";
import {
  getPmRoomListYiLong_api,
  addPromotionNotifyYiLong_api,
  getYiLongPromoteInfo_api,
} from "../../apis/hotelPackage";
import local from "@/utils/local.js";
export default {
  data() {
    return {
      searchData: {
        city: "",
        hotelName: "",
        hotelId: "",
        roomName: "",
        roomId: "",
        page: 1,
        rows: "15",
      },
      hotelNameorId: "酒店名称",
      roomNameorId: "房型ID",
      hotelList: [],
      total: 0,
      tableSwitch: "列表收起",
      checkedColor: 0,
      pmRoomList: [],
      pmVestList: [],
      pmHotelName: "",
      roomIndeterminate: false,
      ischeckAllRoom: false,
      handData: {
        end: "",
        endHour: "",
        endMinute: "",
        hotelSonId: "",
        hotelVestIds: "",
        id: "",
        promotionId: "",
        promotionName: "",
        roomIds: "",
        start: "",
        startHour: "",
        startMinute: "",
        week: "",
        details: [
          {
            continuousDays: "",
            end: "",
            notTime: [],
            predeterminedDays: "",
            priceValue: "",
            promotionMethod: "",
            start: "",
            supportEndHour: "",
            supportStartHour: "",
            tailRoomSalePolicy: "",
            week: "",
          },
        ],
      },

      chooseDateOne: [],
      chooseDateTwo: [],
      weeks: [
        { week: "周一", id: 1 },
        { week: "周二", id: 2 },
        { week: "周三", id: 3 },
        { week: "周四", id: 4 },
        { week: "周五", id: 5 },
        { week: "周六", id: 6 },
        { week: "周日", id: 0 },
      ],
      ischeckAllWeek: true,
      checkedWeeks: [1, 2, 3, 4, 5, 6, 0],
      weekIndeterminate: false,
      ischeckAllWeekTwo: true,
      checkedWeeksTwo: [1, 2, 3, 4, 5, 6, 0],
      weekIndeterminateTwo: false,
      notApplyDate: false, //是否设置不适用日期
      notApplyDateList: [{ chooseDate: [new Date(), new Date()] }], //不适用日期列表
      discountCount: "60",
      discountCount1: "",
      discountCount2: "",
      supportStartHourValue: "",
      supportEndHourValue: "",
      timeList: [
        { value: "24", label: "00:00" },
        { value: "25", label: "01:00" },
        { value: "26", label: "02:00" },
        { value: "27", label: "03:00" },
        { value: "28", label: "04:00" },
        { value: "29", label: "05:00" },
      ],
      timeListTwo: [
        { value: "0", label: "00:00" },
        { value: "1", label: "01:00" },
        { value: "2", label: "02:00" },
        { value: "3", label: "03:00" },
        { value: "4", label: "04:00" },
        { value: "5", label: "05:00" },
        { value: "6", label: "06:00" },
        { value: "7", label: "07:00" },
        { value: "8", label: "08:00" },
        { value: "9", label: "09:00" },
        { value: "10", label: "10:00" },
        { value: "11", label: "11:00" },
        { value: "12", label: "12:00" },
        { value: "13", label: "13:00" },
        { value: "14", label: "14:00" },
        { value: "15", label: "15:00" },
        { value: "16", label: "16:00" },
        { value: "17", label: "17:00" },
        { value: "18", label: "18:00" },
        { value: "19", label: "19:00" },
        { value: "20", label: "20:00" },
        { value: "21", label: "21:00" },
        { value: "22", label: "22:00" },
        { value: "23", label: "23:00" },
      ],
      timeListThree: [
        { value: "1", label: "00:59" },
        { value: "2", label: "01:59" },
        { value: "3", label: "02:59" },
        { value: "4", label: "03:59" },
        { value: "5", label: "04:59" },
        { value: "6", label: "05:59" },
        { value: "7", label: "06:59" },
        { value: "8", label: "07:59" },
        { value: "9", label: "08:59" },
        { value: "10", label: "09:59" },
        { value: "11", label: "10:59" },
        { value: "12", label: "11:59" },
        { value: "13", label: "12:59" },
        { value: "14", label: "13:59" },
        { value: "15", label: "14:59" },
        { value: "16", label: "15:59" },
        { value: "17", label: "16:59" },
        { value: "18", label: "17:59" },
        { value: "19", label: "18:59" },
        { value: "20", label: "19:59" },
        { value: "21", label: "20:59" },
        { value: "22", label: "21:59" },
        { value: "23", label: "22:59" },
        { value: "24", label: "23:59" },
        { value: "25", label: "次日00:59" },
        { value: "26", label: "次日01:59" },
        { value: "27", label: "次日02:59" },
        { value: "28", label: "次日03:59" },
        { value: "29", label: "次日04:59" },
        { value: "30", label: "次日05:59" },
      ],
      timeListFour: [
        { value: "16", label: "16:00" },
        { value: "17", label: "17:00" },
        { value: "18", label: "18:00" },
        { value: "19", label: "19:00" },
        { value: "20", label: "20:00" },
        { value: "21", label: "21:00" },
        { value: "22", label: "22:00" },
        { value: "23", label: "23:00" },
      ],
      submitName: "创建",
      pid: "",
      daysValue: "",
      daysValue1: "",
    };
  },
  created() {
    if (this.$route.query.templateId) {
      this.handData.promotionId = this.$route.query.templateId;
      this.handData.promotionName = this.$route.query.promotionName;
      this.handData.id = "";
      this.searchHotelTableFn();
    } else {
      this.pid = this.$route.query.pid;
      this.submitName = "保存";
      this.getYiLongPromoteInfo();
    }

    if (this.handData.promotionName == "今夜特惠") {
    } else if (this.handData.promotionName == "限时预订") {
    } else if (this.handData.promotionName == "尾房") {
    }
    
  },
  filters: {
    formatHotelName(val) {
      if (val.length < 20) {
        return val;
      } else {
        let newstr = val.substring(0, 16);
        return newstr + "...";
      }
    },
  },
  methods: {
    searchHotelTableFn() {
      local.remove("roomPageHotelId");
      this.searchData.page = 1;
      this.searchHotelTable();
    },
    //根据条件查询酒店列表
    async searchHotelTable() {
      let { code, data } = await hotelList_api(this.searchData);
      if (code == 0) {
        this.hotelList = data.records;
        this.total = data.total;
        if (local.get("roomPageHotelId")) {
          this.getHotelInfo(local.get("roomPageHotelId"));
        } else {
          if (this.hotelList.length > 0) {
            this.searchHotelInfo(
              this.hotelList[0].hotelId,
              0,
              this.hotelList[0].hotelName
            );
          }
        }
      }
    },
    //酒店列表分页
    handleCurrentChange(val) {
      // console.log(val);
      this.searchData.page = val;
      this.searchHotelTable();
    },
    //列表开关
    setTableSwitch() {
      if (this.tableSwitch == "列表收起") {
        this.tableSwitch = "列表展开";
      } else {
        this.tableSwitch = "列表收起";
      }
    },
    searchHotelInfo(id, index, hotelName) {
      local.set("roomPageHotelId", id);
      this.checkedColor = id;
      this.pmHotelName = hotelName;
      this.getHotelInfo(id);
    },
    async getHotelInfo(id) {
      let { code, data } = await getPmRoomListYiLong_api({
        hotelSonId: id,
      });
      if (code == 0) {
        this.handData.hotelSonId = id;
        let roomCount = 0;
        data.list.forEach((item) => {
          let count = 0;
          item.list.forEach((ctem) => {
            if (ctem.isCk == 0) {
              this.$set(ctem, "checked", false);
            } else if (ctem.isCk == 1) {
              count++;
              this.$set(ctem, "checked", true);
            }
          });
          if (count == 0) {
            this.$set(item, "checked", false);
            this.$set(item, "indeterminate", false);
          } else if ((count = item.list.length)) {
            this.$set(item, "checked", true);
            this.$set(item, "indeterminate", false);
            roomCount++;
          } else {
            this.$set(item, "checked", false);
            this.$set(item, "indeterminate", true);
          }
        });
        if (roomCount == 0) {
          this.roomIndeterminate = false;
          this.ischeckAllRoom = false;
        } else if (roomCount == data.list.length) {
          this.roomIndeterminate = false;
          this.ischeckAllRoom = true;
        } else {
          this.roomIndeterminate = true;
          this.ischeckAllRoom = false;
        }

        data.vest.forEach((item) => {
          if (item.isCk == 0) {
            this.$set(item, "checked", false);
          } else if (item.isCk == 1) {
            //  count++;
            this.$set(item, "checked", true);
          }
        });

        this.pmRoomList = data.list;
        this.pmVestList = data.vest;
      }
    },
    //所有房型全选
    handleCheckAllRoomChange(e) {
      let that = this;
      that.roomIndeterminate = false;
      that.ischeckAllRoom = e;
      that.pmRoomList.forEach((item) => {
        item.checked = e;
        item.indeterminate = false;
        item.list.forEach((ctem) => {
          ctem.checked = e;
        });
      });
    },
    //售卖房型全选，也就是物理房型单选
    handleCheckAllRoomItemChange(item, e) {
      item.indeterminate = false;
      item.checked = e;
      item.list.forEach((ctem) => {
        ctem.checked = e;
      });

      this.getIsCheckAll();
    },
    //售卖房型单选
    handleCheckRoomItemChange(ctem, e) {
      ctem.checked = e;
      this.getIsCheckAll();
    },
    //x选择引起的上层全选框变动
    getIsCheckAll() {
      let outCount = 0;
      this.pmRoomList.forEach((item) => {
        let count = 0;
        item.list.forEach((ctem) => {
          if (ctem.checked == true) {
            count++;
          }
        });
        if (count == 0) {
          item.checked = false;
          item.indeterminate = false;
        } else if (count == item.list.length) {
          item.checked = true;
          item.indeterminate = false;
        } else {
          item.checked = false;
          item.indeterminate = true;
        }
        if (item.checked == true) {
          outCount++;
        }
      });
      if (outCount == 0) {
        this.roomIndeterminate = false;
        this.ischeckAllRoom = false;
      } else if (outCount == this.pmRoomList.length) {
        this.roomIndeterminate = false;
        this.ischeckAllRoom = true;
      } else {
        this.roomIndeterminate = true;
        this.ischeckAllRoom = false;
      }
    },
    //关联修改
    handleCheckVestChange(item, e) {
      item.checked = e;
    },
    // 周的全选
    handleCheckAllWeekChange(val) {
      let weekIds = [];
      this.weeks.forEach((item) => {
        weekIds.push(item.id);
      });
      this.checkedWeeks = val ? weekIds : [];
      this.weekIndeterminate = false;
    },
    // 周的单选
    handleCheckedWeeksChange(value) {
      let checkedCount = value.length;
      this.ischeckAllWeek = checkedCount === this.weeks.length;
      this.weekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    // 周的全选2
    handleCheckAllWeekChangeTwo(val) {
      let weekIds = [];
      this.weeks.forEach((item) => {
        weekIds.push(item.id);
      });
      this.checkedWeeksTwo = val ? weekIds : [];
      this.weekIndeterminateTwo = false;
    },
    // 周的单选2
    handleCheckedWeeksChangeTwo(value) {
      let checkedCount = value.length;
      this.ischeckAllWeekTwo = checkedCount === this.weeks.length;
      this.weekIndeterminateTwo =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    //增加不适用日期
    addNotApplyDate(item, index) {
      this.notApplyDateList.splice(index, 0, item);
      this.notApplyDateList = JSON.parse(JSON.stringify(this.notApplyDateList));
    },
    //减少不适用日期
    delNotApplyDate(item, index) {
      this.notApplyDateList.splice(index, 1);
      this.notApplyDateList = JSON.parse(JSON.stringify(this.notApplyDateList));
    },
    addPromotionNotify() {
      //预定时间
      if (this.chooseDateOne.length > 0) {
        this.handData.start = this.chooseDateOne[0];
        this.handData.end = this.chooseDateOne[1];
      } else {
        this.handData.start = "";
        this.handData.end = "";
      }
      //预定时间适用星期
      let count1;
      let count2;
      let count3;
      let count4;
      let count5;
      let count6;
      let count7;
      if (this.checkedWeeks.indexOf(6) == -1) {
        count1 = "0";
      } else {
        count1 = "1";
      }
      if (this.checkedWeeks.indexOf(5) == -1) {
        count2 = "0";
      } else {
        count2 = "1";
      }
      if (this.checkedWeeks.indexOf(4) == -1) {
        count3 = "0";
      } else {
        count3 = "1";
      }
      if (this.checkedWeeks.indexOf(3) == -1) {
        count4 = "0";
      } else {
        count4 = "1";
      }
      if (this.checkedWeeks.indexOf(2) == -1) {
        count5 = "0";
      } else {
        count5 = "1";
      }
      if (this.checkedWeeks.indexOf(1) == -1) {
        count6 = "0";
      } else {
        count6 = "1";
      }
      if (this.checkedWeeks.indexOf(0) == -1) {
        count7 = "0";
      } else {
        count7 = "1";
      }
      this.handData.week =
        count1 + count2 + count3 + count4 + count5 + count6 + count7;

      //入住时间
      if (this.chooseDateTwo.length > 0) {
        this.handData.details[0].start = this.chooseDateTwo[0];
        this.handData.details[0].end = this.chooseDateTwo[1];
      } else {
        this.handData.details[0].start = "";
        this.handData.details[0].end = "";
      }

      //入住适用星期
      let count21;
      let count22;
      let count23;
      let count24;
      let count25;
      let count26;
      let count27;
      if (this.checkedWeeksTwo.indexOf(6) == -1) {
        count21 = "0";
      } else {
        count21 = "1";
      }
      if (this.checkedWeeksTwo.indexOf(5) == -1) {
        count22 = "0";
      } else {
        count22 = "1";
      }
      if (this.checkedWeeksTwo.indexOf(4) == -1) {
        count23 = "0";
      } else {
        count23 = "1";
      }
      if (this.checkedWeeksTwo.indexOf(3) == -1) {
        count24 = "0";
      } else {
        count24 = "1";
      }
      if (this.checkedWeeksTwo.indexOf(2) == -1) {
        count25 = "0";
      } else {
        count25 = "1";
      }
      if (this.checkedWeeksTwo.indexOf(1) == -1) {
        count26 = "0";
      } else {
        count26 = "1";
      }
      if (this.checkedWeeksTwo.indexOf(0) == -1) {
        count27 = "0";
      } else {
        count27 = "1";
      }
      this.handData.details[0].week =
        count21 + count22 + count23 + count24 + count25 + count26 + count27;

      //不适用时间段
      if (this.notApplyDate) {
        this.handData.details[0].notTime = [];
        this.notApplyDateList.forEach((item) => {
          this.handData.details[0].notTime.push(item.chooseDate);
        });
      } else {
        this.handData.details[0].notTime = [];
      }

      //优惠形式促销形式，2为折扣，1为立减
      if (this.discountCount == 120) {
        this.handData.details[0].promotionMethod = '1';
        this.handData.details[0].priceValue = this.discountCount2;
      } else {
        this.handData.details[0].promotionMethod = '2';
        if (this.discountCount == 110) {
          this.handData.details[0].priceValue =
            Number(this.discountCount1) * 10;
        } else {
          this.handData.details[0].priceValue = this.discountCount;
        }
      }

      //售卖时间段 hour min
      if (
        this.handData.promotionName == "今夜特惠" ||
        this.handData.promotionName == "尾房"
      ) {
        this.handData.details[0].supportStartHour = this.supportStartHourValue;
        this.handData.details[0].supportEndHour = "30";
      } else if (this.handData.promotionName == "限时预订") {
      } else if (this.handData.promotionName == "尾房") {
      }

      //提前预定天数
      if (this.handData.promotionName == "提前预定") {
        if (this.daysValue == "-1") {
          this.handData.details[0].predeterminedDays = this.daysValue1;
        } else {
          this.handData.details[0].predeterminedDays = this.daysValue;
        }
      }

      //连住
      if (this.handData.promotionName == "多天连住") {
        if (this.daysValue == "-1") {
          this.handData.details[0].continuousDays = this.daysValue1;
        } else {
          this.handData.details[0].continuousDays = this.daysValue;
        }
      }

      //促销渠道  关联修改
      let vestIdsList = [];
      this.pmVestList.forEach((item) => {
        if (item.checked == true) {
          vestIdsList.push(item.hotelVestId);
        }
      });
      //促销房型
      let roomIdsList = [];
      this.pmRoomList.forEach((item) => {
        item.list.forEach((ctem) => {
          if (ctem.checked == true) {
            roomIdsList.push(ctem.roomId);
          }
        });
      });

      if (!this.handData.hotelSonId || roomIdsList.length == 0) {
        this.$message.error("请选择促销房型");
      } else {
        this.handData.roomIds = roomIdsList.join(",");
        if (vestIdsList.length == 0) {
          this.$message.error("请选择促销渠道");
        } else {
          this.handData.hotelVestIds = vestIdsList.join(",");
          if (!this.handData.details[0].end) {
            this.$message.error("请选择促销时间");
          } else {
            this.addPromotionNotifyFn();
          }
        }
      }
    },
    async addPromotionNotifyFn() {
      let { code, data } = await addPromotionNotifyYiLong_api(this.handData);
      if (code == 0) {
        this.$message.success(
          `成功${this.submitName}${this.handData.promotionName}促销`
        );
      }
    },
    //获取艺龙促销信息
    async getYiLongPromoteInfo() {
      let { code, data } = await getYiLongPromoteInfo_api({
        pid: this.pid,
      });
      if (code == 0) {
        this.handData = data;


        let roomCount = 0;
        data.prm.list.forEach((item) => {
          let count = 0;
          item.list.forEach((ctem) => {
            if (ctem.isCk == 0) {
              this.$set(ctem, "checked", false);
            } else if (ctem.isCk == 1) {
              count++;
              this.$set(ctem, "checked", true);
            }
          });
          if (count == 0) {
            this.$set(item, "checked", false);
            this.$set(item, "indeterminate", false);
          } else if ((count = item.list.length)) {
            this.$set(item, "checked", true);
            this.$set(item, "indeterminate", false);
            roomCount++;
          } else {
            this.$set(item, "checked", false);
            this.$set(item, "indeterminate", true);
          }
        });
        if (roomCount == 0) {
          this.roomIndeterminate = false;
          this.ischeckAllRoom = false;
        } else if (roomCount == data.prm.list.length) {
          this.roomIndeterminate = false;
          this.ischeckAllRoom = true;
        } else {
          this.roomIndeterminate = true;
          this.ischeckAllRoom = false;
        }
        data.prm.vest.forEach((item) => {
          if (item.isCk == 0) {
            this.$set(item, "checked", false);
          } else if (item.isCk == 1) {
            // count++;
            this.$set(item, "checked", true);
          }
        });

        this.pmRoomList = data.prm.list;
        this.pmVestList = data.prm.vest;







        this.chooseDateOne = [];
        this.pmHotelName = this.handData.hotelName;
        //预定时间
        this.chooseDateOne.push(this.handData.start.substring(0, 10));
        this.chooseDateOne.push(this.handData.end.substring(0, 10));
        //入住时间
        this.chooseDateTwo = [];
        this.chooseDateTwo.push(
          this.handData.details[0].start.substring(0, 10)
        );
        this.chooseDateTwo.push(this.handData.details[0].end.substring(0, 10));
        //不适用时间段
        if (
          this.handData.details[0].notTime &&
          this.handData.details[0].notTime.length > 0
        ) {
          this.notApplyDate = true;
          this.notApplyDateList = [];
          this.handData.details[0].notTime.forEach((item) => {
            this.notApplyDateList.push({ chooseDate: item });
          });
        }

        //外层不适用星期
        this.checkedWeeks = [];
        let weekCount = 0;
        if (this.handData.week.charAt(0) == 1) {
          this.checkedWeeks.push(6);
          weekCount++;
        }
        if (this.handData.week.charAt(1) == 1) {
          this.checkedWeeks.push(5);
          weekCount++;
        }
        if (this.handData.week.charAt(2) == 1) {
          this.checkedWeeks.push(4);
          weekCount++;
        }
        if (this.handData.week.charAt(3) == 1) {
          this.checkedWeeks.push(3);
          weekCount++;
        }
        if (this.handData.week.charAt(4) == 1) {
          this.checkedWeeks.push(2);
          weekCount++;
        }
        if (this.handData.week.charAt(5) == 1) {
          this.checkedWeeks.push(1);
          weekCount++;
        }
        if (this.handData.week.charAt(6) == 1) {
          this.checkedWeeks.push(0);
          weekCount++;
        }

        if (weekCount == 0) {
          this.ischeckAllWeek = false;
          this.weekIndeterminate = false;
        } else if (weekCount == 7) {
          this.ischeckAllWeek = true;
          this.weekIndeterminate = false;
        } else {
          this.ischeckAllWeek = false;
          this.weekIndeterminate = true;
        }

        //内层不适用星期
        this.checkedWeeksTwo = [];
        let weekCountTwo = 0;
        if (this.handData.details[0].week.charAt(0) == 1) {
          this.checkedWeeksTwo.push(6);
          weekCountTwo++;
        }
        if (this.handData.details[0].week.charAt(1) == 1) {
          this.checkedWeeksTwo.push(5);
          weekCountTwo++;
        }
        if (this.handData.details[0].week.charAt(2) == 1) {
          this.checkedWeeksTwo.push(4);
          weekCountTwo++;
        }
        if (this.handData.details[0].week.charAt(3) == 1) {
          this.checkedWeeksTwo.push(3);
          weekCountTwo++;
        }
        if (this.handData.details[0].week.charAt(4) == 1) {
          this.checkedWeeksTwo.push(2);
          weekCountTwo++;
        }
        if (this.handData.details[0].week.charAt(5) == 1) {
          this.checkedWeeksTwo.push(1);
          weekCountTwo++;
        }
        if (this.handData.details[0].week.charAt(6) == 1) {
          this.checkedWeeksTwo.push(0);
          weekCountTwo++;
        }

        if (weekCountTwo == 0) {
          this.ischeckAllWeekTwo = false;
          this.weekIndeterminateTwo = false;
        } else if (weekCountTwo == 7) {
          this.ischeckAllWeekTwo = true;
          this.weekIndeterminateTwo = false;
        } else {
          this.ischeckAllWeekTwo = false;
          this.weekIndeterminateTwo = true;
        }

        //优惠形式促销形式，2为折扣，1为立减
        if (this.handData.details[0].promotionMethod == 1) {
          this.discountCount = 120;
          this.discountCount2 = this.handData.details[0].priceValue;
        } else {
          if (
            this.handData.details[0].priceValue == "60" ||
            this.handData.details[0].priceValue == "50" ||
            this.handData.details[0].priceValue == "40" ||
            this.handData.details[0].priceValue == "30"
          ) {
            this.discountCount = this.handData.details[0].priceValue;
          } else {
            this.discountCount = "110";
            this.discountCount1 =
              Number(this.handData.details[0].priceValue) / 10;
          }
        }

        if (
          this.handData.promotionName == "今夜特惠" ||
          this.handData.promotionName == "尾房"
        ) {
          this.supportStartHourValue =
            this.handData.details[0].supportStartHour;
        } else if (this.handData.promotionName == "限时预订") {
          this.supportStartHourValue =
            this.handData.details[0].supportStartHour;
          this.supportEndHourValue = this.handData.details[0].supportEndHour;
        }

        //提前预定天数
         if(this.handData.promotionName == "提前预定"){
            if(this.handData.details[0].predeterminedDays==2||
              this.handData.details[0].predeterminedDays==3||
              this.handData.details[0].predeterminedDays==4||
              this.handData.details[0].predeterminedDays==5){
              this.daysValue=this.handData.details[0].predeterminedDays
            }else{
              this.daysValue='-1'
              this.daysValue1=this.handData.details[0].predeterminedDays
            }
         }


        //连住
         if(this.handData.promotionName == "多天连住"){
            if(this.handData.details[0].continuousDays==2||
              this.handData.details[0].continuousDays==3||
              this.handData.details[0].continuousDays==4||
              this.handData.details[0].continuousDays==5){
              this.daysValue=this.handData.details[0].continuousDays
            }else{
              this.daysValue='-1'
              this.daysValue1=this.handData.details[0].continuousDays
            }
         }







      }
    },
    formatDate(date) {
      let myDate = new Date(date);
      let y = myDate.getFullYear();
      let m = myDate.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }
      let d = myDate.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      return y + "-" + m + "-" + d;
    },
  },
};
</script>

<style lang="less" scoped>
.addPromotionbox {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 20px;
}
.titlebox {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #256def;
  border-radius: 10px 10px 0px 0px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fafcff;
}
.marginleft10 {
  margin-left: 10px;
  cursor: pointer;
}
.titleboxtwo {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.mainbox {
  margin: 0 auto;
  width: 1120px;
  padding: 20px;
  background: #f1f5fb;
  border-radius: 10px;
  position: relative;
}
.maintitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}
.margintop10 {
  margin-top: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.margintop40 {
  margin-top: 40px;
}
.inlineblockbox {
  display: inline-block;
  vertical-align: top;
}
.iconbox {
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  background-color: #256def;
  border-radius: 50%;
  color: #ffffff;
}
.margintop5 {
  margin-top: 5px;
}
.margintop3 {
  margin-top: 3px;
}
.marginright10 {
  margin-right: 10px;
}
.marginbottom10 {
  margin-bottom: 10px;
}
.checkOne {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 20px;
}
.roomBoxOneOff {
  width: 56px;
  height: 28px;
  line-height: 28px;
  color: #256ef1;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  //   display: inline-block;
  float: right;
}
.roomBoxOneHotelNameBox {
  width: 100%;
  background-color: #f1f5fb;
}
.roomBoxOneHotelName {
  display: inline-block;
  cursor: pointer;
  width: 20%;
  margin: 10px auto;
  //text-align: center;
}
.roomBoxOneHotelName:hover {
  color: #256def;
}
.roomPagin {
  text-align: right;
}
.margintop30 {
  margin-top: 30px;
}
.marginright20 {
  margin-right: 20px;
}
.marginbottom20 {
  margin-bottom: 20px;
}
.borderbottom {
  border-bottom: 1px solid #dee4ed;
  padding-bottom: 10px;
}
.width90 {
  width: 148px;
}
.selectbox {
  width: 180px;
  margin-right: 20px;
}
.discountCountLabelBox {
  margin-top: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.newboxone {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}
.newboxlabel {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.marginleft90 {
  margin-left: 88px;
}
.marginleft40 {
  margin-left: 40px;
}
.lineheight28 {
  line-height: 28px;
}
</style>>


